.underline {
  background: linear-gradient(
      to right,
      rgba(100, 200, 200, 0),
      rgba(100, 200, 200, 0)
    ),
    linear-gradient(to right, var(--color), var(--color));
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  animation: myAnimation 750ms ease-in-out 250ms forwards;
}

@keyframes myAnimation {
  to {
    background-size: 0 0.1em, 100% 0.1em;
  }
}
