.story-card {
  aspect-ratio: 608 / 1080;
  background: white;
  margin: 0 auto;
  border-radius: 0px;
  overflow: hidden;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .story-card {
    height: auto;
    margin-top: 0px;
    width: 100%;
  }
}
